mapbox-search-listbox {
    z-index: 1301;
    position: relative;
}

  body {
    height: 100%;
    overflow : scroll;
    margin: 0px;
  }

  html {
    height: 100%;
    overflow : scroll;
    margin: 0px;
  }

  #root {
    overflow : scroll;
    margin: 0px;
  }

.App {
    height: 100%;
    background-color: #f4f5f7;
  }

.App:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em; 
  background-color: transparent;
  border: 1.1em solid white;
  border-left: 1.1em solid #31A1F1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}